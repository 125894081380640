import {API_USER_SETTINGS_URL} from "../config";
import axios, {AxiosResponse} from "axios";
import {UserSettings} from "../interfaces/UserSettings";

class UserSettingsService {

    url: string = "";

    constructor(api: string = API_USER_SETTINGS_URL) {
        this.url = api;
    }

    async findByUser(userId: number): Promise<UserSettings> {
        const response: AxiosResponse<UserSettings> = await axios.get<UserSettings>(`${this.url}/${userId}`);
        return response.data;
    }

    async updateByUser(userId: number, entity: UserSettings): Promise<UserSettings> {
        const response = await axios.put<UserSettings>(`${this.url}/${userId}`, entity);
        return response.data;
    }
}

export const userSettingsService: UserSettingsService = new UserSettingsService();