import UserDataImportFieldSettings from "./UserDataImportFieldSettings";

export interface UserDataImportSettings {
    skipEmptyValue: boolean | undefined;
    items: UserDataImportFieldSettings[] | undefined;
}

export const initialUserDataImportSettingsItems: UserDataImportFieldSettings[] = [
    {field: "profile.insurancePolicyNumber", enabled: true},
    {field: "profile.secondName", enabled: true},
    {field: "profile.firstName", enabled: true},
    {field: "profile.thirdName", enabled: true},
    {field: "profile.dateOfBirth", enabled: true},
];

export const initialUserDataImportSettings: UserDataImportSettings = {
    skipEmptyValue: false,
    items: initialUserDataImportSettingsItems
}
