import React, {createContext} from "react";
import {useAuth} from "../security/AuthProvider";
import {useUpdateEffect} from "primereact/hooks";
import useUserNotification from "../hooks/useUserNotification";
import {errorMessage} from "../helpers/axiosError";
import {initialUserSettings, UserSettings} from "../interfaces/UserSettings";
import {userSettingsService} from "../service/UserSettingsService";

type UserSettingsType = {
    userSettings: UserSettings;
    setUserSettings: (userSettings: UserSettings) => void;
    reload(): void;
    save(onSave?: () => void): void;
    saveSettings(userSettings: UserSettings, onSave?: () => void): void;
}

const UserSettingsContext: React.Context<UserSettingsType> = createContext<UserSettingsType>({
    userSettings: initialUserSettings,
    setUserSettings(userSettings: UserSettings) {
    },
    reload() {
    },
    save(onSave?: () => void) {
    },
    saveSettings(userSettings, onSave?: () => void) {
    }
});

export default function UserSettingsProvider({children}: any) {

    const {user} = useAuth();
    const {showError, showSuccess} = useUserNotification();
    const [contextUserSettings, setContextUserSettings] =
        React.useState<UserSettings>(initialUserSettings);

    const contextReload = () => {
        if (!user || !user.id) return

        userSettingsService
            .findByUser(user.id)
            .then((loadedUserSettings) => {
                setContextUserSettings(loadedUserSettings);

            })
            .catch(reason => showError({
                summary: "Загрузка пользовательских настроек",
                detail: errorMessage(reason),
            }));
    };

    useUpdateEffect(() => contextReload(), [user]);

    const contextSave = (onSave: () => void) => {
        contextSaveSettings(contextUserSettings, onSave);
    };

    const contextSaveSettings = (userSettings: UserSettings, onSave: () => void) => {
        if (!user || !user.id)
            throw Error("Пользователь не определён.");

        userSettingsService
            .updateByUser(user.id, userSettings)
            .then((loadedUserSettings) => {
                user.settings = loadedUserSettings;
                setContextUserSettings(loadedUserSettings);
                showSuccess({
                    summary: "Сохранение пользовательских настроек",
                    detail: "Настройки сохранены."
                });
                if (onSave) onSave();

            })
            .catch(reason => showError({
                summary: "Сохранение пользовательских настроек",
                detail: errorMessage(reason)
            }));
    };

    const contextValue: UserSettingsType = {
        userSettings: contextUserSettings,
        setUserSettings: setContextUserSettings,
        reload: contextReload,
        save: contextSave,
        saveSettings: contextSaveSettings
    };

    return (
        <UserSettingsContext.Provider value={contextValue}>
            {children}
        </UserSettingsContext.Provider>
    );
}

export const useUserSettings = () => {
    return React.useContext(UserSettingsContext);
}

