import {API_USERS_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import User from "../interfaces/User.interface";
import axios from "axios";

interface PasswordChangeRequest {
    currentPassword: string;
    newPassword: string;
}

export class UserService extends BaseDataService<User> {

    constructor() {
        super(API_USERS_URL);
    }

    async changePassword(id: number, currentPassword: string, newPassword: string) {
        const data: PasswordChangeRequest = {
            currentPassword: currentPassword,
            newPassword: newPassword
        };
        const response = await axios.patch(`${this.url}/${id}/password`, data);
        return response.data;
    }

    async resetPassword(id: number, password: string) {
        //const data = {password: password};
        const request = `${this.url}/${id}/password/reset?password=${encodeURIComponent(password)}`;
        const response = await axios.patch(request);
        return response.data;
    }

    async search(search: string) {
        if (!search || search.length === 0) return;
        const request = `${this.url}/search/${encodeURIComponent(search)}`;
        const response = await axios.get(request);
        return response.data;
    }
}
export const userService: UserService = new UserService();