import React, {useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {BlockUI} from "primereact/blockui";
import {DataTable, DataTableDataSelectableEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import useUserNotification from "../hooks/useUserNotification";
import {Checkbox} from "primereact/checkbox";
import {userDataImportService} from "../service/exchange/excel/UserDataImportService";
import {FieldMetadata} from "../interfaces/FieldMetadata";
import {errorMessage} from "../helpers/axiosError";
import {useUserSettings} from "../providers/UserSettingsProvider";
import {
    initialUserDataImportSettings,
    initialUserDataImportSettingsItems,
    UserDataImportSettings
} from "../interfaces/UserDataImportSettings";
import SaveButton from "../components/common/buttons/SaveButton";
import CancelButton from "../components/common/buttons/CancelButton";
import UserDataImportFieldSettings from "../interfaces/UserDataImportFieldSettings";
import {UserSettings} from "../interfaces/UserSettings";

interface UserDataImportSettingsPanelProps {
    onSave: (userDataImportSettings: UserDataImportSettings) => void;
    onCancel?: () => void;
}

const requiredFields: string[] = [
    "profile.insurancePolicyNumber",
    "profile.secondName",
    "profile.firstName",
    "profile.thirdName",
    "profile.dateOfBirth",
];

export default function UserDataImportSettingsPanel(props: UserDataImportSettingsPanelProps) {

    const {userSettings, setUserSettings, saveSettings} = useUserSettings();
    const {showError} = useUserNotification();

    const [busy, setBusy] = useState<boolean>(false);
    const [editedUserSettings, setEditedUserSettings] = useState<UserSettings>(Object.assign({}, userSettings));
    const [fieldsMetadata, setFieldsMetadata] = useState<FieldMetadata[]>([]);
    const [selected, setSelected] = useState<FieldMetadata[]>([]);


    const selectedFieldsMetadataToSettingsItems = (fms: FieldMetadata[]): UserDataImportFieldSettings[] => {
        return fms.map((f: FieldMetadata) => {
            let enabled = selected.some(sf => sf.field === f.field)

            return ({
                field: f.field,
                enabled: enabled,
            });
        });
    };

    const reload = () => {
        if (!editedUserSettings.userDataImportSettings) {
            editedUserSettings.userDataImportSettings = initialUserDataImportSettings;
        }

        if (editedUserSettings.userDataImportSettings && !editedUserSettings.userDataImportSettings.items) {
            editedUserSettings.userDataImportSettings.items = initialUserDataImportSettingsItems;
        }

        userDataImportService
            .fieldsMetadata()
            .then(response => {
                const fms: FieldMetadata[] = response.data;
                const selection: FieldMetadata[] = [];
                const realItems: UserDataImportFieldSettings[] = fms.map((f: FieldMetadata) => {
                    const isRequired = requiredFields.includes(f.field);
                    const wasEnabled = userSettings.userDataImportSettings?.items?.some(
                        i => i.field === f.field && i.enabled) || false;
                    const enabled = isRequired || wasEnabled;

                    f.selectable = !isRequired;

                    if (enabled) selection.push(f);

                    return ({
                        field: f.field,
                        enabled: wasEnabled,
                    });
                });

                if (!editedUserSettings.userDataImportSettings)
                    throw Error("Пользовательские настройки не определены.");

                editedUserSettings.userDataImportSettings.items = realItems;

                setFieldsMetadata(fms);
                setSelected(selection);

            })
            .catch(reason => {
                setFieldsMetadata([]);
                setSelected([]);
                showError({
                    summary: "Загрузка метаданных",
                    detail: errorMessage(reason)
                })
            })
            .finally(() => setBusy(false))

    }

    useMountEffect(() => reload());

    const isRowSelectable = (event: DataTableDataSelectableEvent) => {
        const data: FieldMetadata = event.data as FieldMetadata;
        return data.selectable;
    }

    if (!editedUserSettings.userDataImportSettings) return (
        <div>Пользовательские настройки не определены.</div>
    );

    return (
        <div className="flex flex-column gap-4">
            <BlockUI blocked={busy}>
                <div className="flex flex-column gap-2">
                    <p className={"note"}>Эта совокупность настроек сохраняется в профиле текущего пользователя и
                        связана только с ним. Каждый пользователь системы может индивидуализировать настройки согласно
                        своим предпочтениям и характеру выполняемой работы.</p>
                    <div className="flex flex-row flex-nowrap align-items-stretch gap-2">

                        <Checkbox id="skipEmptyValue"
                                  checked={editedUserSettings.userDataImportSettings.skipEmptyValue || false}
                                  onChange={(e) =>
                                      setEditedUserSettings(prev => ({
                                          ...prev,
                                          userDataImportSettings: {
                                              ...prev.userDataImportSettings,
                                              ...{
                                                  items: prev.userDataImportSettings?.items,
                                                  skipEmptyValue: e.target.checked
                                              }
                                          }
                                      }))}/>

                        <div className="flex flex-column">
                            <label htmlFor="skipEmptyValue">Пропускать пустые ячейки</label>
                            <div className="note">Игнорировать пустые ячейки при сравнении записей базы данных и
                                импортируемого файла.
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="note">Только атрибуты, отмеченные в таблице ниже, будут учитываться при сравнении
                            базы данных и
                            импортируемого файла.</p>

                        <DataTable value={fieldsMetadata} dataKey={"field"}
                                   selectionMode={"checkbox"}
                                   selection={selected}
                                   onSelectionChange={(e) => setSelected(e.value)}
                                   isDataSelectable={isRowSelectable}
                                   scrollable scrollHeight={"500px"}
                                   size={"small"}>

                            <Column selectionMode="multiple"
                                    headerStyle={{width: '3rem'}}/>
                            <Column header={"Название атрибута"}
                                    field={"title"}
                                    dataType={"text"}/>
                            <Column header={"Системное имя"}
                                    field={"field"}
                                    dataType={"text"}/>

                        </DataTable>

                        <div className={"mt-5"}>
                            <SaveButton disabled={busy}
                                        onClick={() => {
                                            if (editedUserSettings.userDataImportSettings &&
                                                editedUserSettings.userDataImportSettings.items) {

                                                editedUserSettings.userDataImportSettings.items =
                                                    selectedFieldsMetadataToSettingsItems(fieldsMetadata);

                                                setUserSettings(editedUserSettings);
                                                saveSettings(editedUserSettings);
                                                if (props.onSave) props.onSave(editedUserSettings.userDataImportSettings);
                                            }
                                        }}/>
                            <CancelButton disabled={busy}
                                          onClick={() => {
                                              if (props.onCancel) props.onCancel();
                                          }}/>
                        </div>
                    </div>
                </div>
            </BlockUI>
        </div>
    );
};