import React from 'react';
import ReactDOM from 'react-dom/client';
import axios, {AxiosError} from "axios";
import {PrimeReactProvider} from "primereact/api";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./security/AuthProvider";
import Maintenance from "./components/Maintenance";
import UserNotificationProvider from './providers/UserNotificationProvider';
import AppSettingsProvider from "./providers/AppSettingsProvider";
import './index.css';
import {LS_TOKEN_KEY} from "./config";
import UserSettingsProvider from "./providers/UserSettingsProvider";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export function setupAxios() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem(LS_TOKEN_KEY);
            //console.log("Axios.interceptors.request.use", token);
            if (token) config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    /*axios.interceptors.response.use((originalResponse) => {

    });*/
}

setupAxios();


const config = {
    ripple: true,
    unstyled: false,
    locale: 'ru'
};

Promise.all([]).then(() => {

    root.render(
        <React.StrictMode>
            <PrimeReactProvider value={config}>
                <BrowserRouter>
                    <UserNotificationProvider>
                        <AuthProvider>
                            <AppSettingsProvider>
                                <UserSettingsProvider>
                                    <App/>
                                </UserSettingsProvider>
                            </AppSettingsProvider>
                        </AuthProvider>
                    </UserNotificationProvider>
                </BrowserRouter>
            </PrimeReactProvider>
        </React.StrictMode>
    );
})
    .catch((reason: AxiosError) => {
        console.error(reason);

        if (reason.code === 'ERR_NETWORK' || reason.code === 'ERR_BAD_REQUEST')
            root.render(
                <React.StrictMode>
                    <Maintenance/>
                </React.StrictMode>
            );
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
